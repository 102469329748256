import React from 'react';
import classnames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';

import { INTERNAL_LINKS_REGXS } from '../Constants';
import IconDownload from 'static/images/icons/icon-download.inline.svg';
import IconChevron from 'static/images/icons/icon-chevron.inline.svg';
import IconExternalLink from 'static/images/icons/icon-external-link.inline.svg';

import './Link.scss';


const Link = ({ children, to, activeClassName, target, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = !target && /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }

  const _target = target || (INTERNAL_LINKS_REGXS.some(rx => rx.test(to)) ? "" : "_blank");
  return (
    <a href={to} target={_target} rel="noopener noreferrer" {...other}>
      {children}
    </a>
  );
};
export default Link;

export const MainNavLink = props => {
  const finalClassName = classnames(
    'aaa-link-navigation-main',
    props.className
  )
  return <Link 
    {...props}
    activeClassName={`active`}
    className={finalClassName} 
  />
};

export const SmallTextLink = props => {
  return <Link
    {...props}
    className={classnames(`aaa-link-text-small`, props.className)}
  />
};

export const RegularLink = props => {
  return <Link
    {...props}
    activeClassName={`active`}
    className={classnames(`aaa-link-regular`, props.className)}
  />
};

export const DownloadLink = ({ children, to, ...props }) => (
  <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
    {children}
    <span className="aaa-link-download__icon">
      <IconDownload />
    </span>
  </a>
);

export const ArrowLink = ({ children, ...props }) => (
  <RegularLink {...props}>
    {children}
    <span className="aaa-link-arrow__icon">
      <IconChevron />
    </span>
  </RegularLink>
);

export const ExternalIconLink = ({ children, ...props }) => (
  <RegularLink {...props}>
    {children}
    <span className="aaa-link-arrow__icon">
      <IconExternalLink />
    </span>
  </RegularLink>
);