import React from 'react';
import Link from 'components/Shared/Link/Link';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import IconClose from 'static/images/icons/icon-close.inline.svg';
import './Notice.scss';


const Notice = ({ onClose }) => (
  <div className='aaa-notice'>
    <Link onClick={() => onClose()} to='https://smarthome.calstate.aaa.com/news' target="_blank">
      <ContentBox>
        <span className='message'>
          Learn how AAA Smart Home Security is helping Members and employees stay safe and healthy.
        </span>
      </ContentBox>
    </Link>
    <span className='close' onClick={() => onClose()}>
      <IconClose />
    </span>
  </div>
);

export default Notice;
