import React, { Component } from 'react';
import classnames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import queryString from 'query-string'
import OutsideClickHandler from 'react-outside-click-handler';

import Link from 'components/Shared/Link/Link';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { CTAButton, SecondaryButton } from 'components/Shared/Button/Button';

import { 
  CallUsNavigation,
  MainNavigation,
  MobileNavigation,
  ProfileNavigation
} from './Navigation';

import logo from 'static/images/header/aaa-smart-home-logo.svg';
import crossIcon from 'static/images/icons/cross.svg';
import menuIcon from 'static/images/icons/menu.svg';

import './Header.scss';

class HeaderSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      mounted: false
    };
    const { type } = props;
    this.header = props.data.allHeaderYaml.nodes.find(
      (header) => header.type === type
    );
  }

  componentDidMount() {
    this.setState({ 
      mounted: true,
      search: queryString.parse(this.props.location.search)
    });
  }

  closeMobileMenu = () => {
    this.setState({ menuOpen: false });
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
  }

  render() {
    const header = this.header;
    let logoRedirect = this.props.logoLink || (header.logoLink ? header.logoLink.url : '/');
    if(logoRedirect === 'source') {
      logoRedirect = this.state.search && this.state.search.source ? this.state.search.source : '/';
    }
    const mobileMenuItems = [
      ...header.mainNavigation,
      ...header.profileMenu || [],
      ...(header.callUsMenu && header.callUsMenu.items || [])
    ];

    return (
      <header id="header" className="header">
        <div className="header__level-1">
          {header.callUsMenu && (
            <ContentBox>
              <CallUsNavigation items={header.callUsMenu.items} />
              {header.ctaButton.title.length > 0 && (
                <CTAButton className="header__cta-button">
                  {header.ctaButton.title}
                </CTAButton>
              )}
            </ContentBox>
          )}
        </div>
        <div className="header__level-2">
          <ContentBox>
            <div className="header__logo">
              <div className="header__smart-tab">
                <Link to={logoRedirect}>
                  <img src={logo} />
                </Link>
              </div>
            </div>
            <MainNavigation items={header.mainNavigation} />
            <div className="header__actions">
              {header.profileMenu &&
              <ProfileNavigation items={header.profileMenu} />}
            </div>
          </ContentBox>
        </div>
        <div className={`header__mobile ${this.state.menuOpen ? 'menu-open' : ''}`}>
          <OutsideClickHandler display='flex' onOutsideClick={() => this.closeMobileMenu()}>
            <ContentBox>
              <div className="header__logo">
                <div className="header__smart-tab">
                  <Link to={logoRedirect}>
                    <img src={logo} />
                  </Link>
                </div>
              </div>
              {mobileMenuItems.length>0 && (
                <SecondaryButton
                  className="header__mobile__button"
                  onClick={() => this.toggleMobileMenu()}
                >
                  <img
                    src={this.state.menuOpen ? crossIcon : menuIcon}
                    alt="Toggle Menu"
                  />
                </SecondaryButton>
              )}
            </ContentBox>
            <MobileNavigation
              items={mobileMenuItems}
              expanded={this.state.menuOpen}
              onSelect={() => this.closeMobileMenu()}
            />
          </OutsideClickHandler>
          <div className={classnames('aaa-mobile-nav-overlay', {'expanded': this.state.menuOpen,})}/>
        </div>
      </header>
    );
  }
}

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allHeaderYaml {
          nodes {
            type
            logoLink {
              url
            }
            callUsMenu {
              items {
                label
                text
                url
              }
            }
            ctaButton {
              title
            }
            mainNavigation {
              text
              url
              newTab
              spacer
              submenu {
                type
                items {
                  image {
                    name
                    childImageSharp {
                      fluid(maxWidth: 134) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  text
                  url
                }
              }
            }
            profileMenu {
              text
              url
            }
          }
        }
      }
    `}
    render={data => <HeaderSource data={data} {...props} />}
  />
);

const HeaderWithLocation =  props => (
  <Location>
    {locationProps => <Header {...locationProps} {...props} />}
  </Location>
);

export default HeaderWithLocation;
