import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Location } from '@reach/router';
import format from 'string-template';
import moment from 'moment-timezone';
import { MetricsElement } from 'react-metrics';
import classnames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import Link, { RegularLink } from 'components/Shared/Link/Link';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';

import './Footer.scss';

const FooterContacts = ({contacts, socials}) => (
  <div className="aaa-footer__contacts">
    {contacts.map((contact, i) => (
      <div className="aaa-footer__contact" key={i}>
        <span className="aaa-footer__links-title">{contact.title}</span>
        <MetricsElement>
          <RegularLink
            className="aaa-footer__link"
            to={contact.link.url}
            data-metrics-event-name="footer_nav_click"
            data-metrics-label={contact.link.text}
          >
            {contact.link.text}
          </RegularLink>
        </MetricsElement>
      </div>
    ))}
    <div className="aaa-footer__socials">
      {socials.map((social, i) => (
        <RegularLink
          key={i}
          className="aaa-footer__social-link"
          to={social.url}
          data-metrics-event-name="footer_nav_click"
          data-metrics-label={social.title}
        >
          <Img fluid={social.image.childImageSharp.fluid} />
        </RegularLink>
      ))}
    </div>
  </div>
);

const FooterLinksCol = ({ links }) => {
  return (
    <Location>
      {({ location }) => (
        <ul className="aaa-footer__links">
          {links.map(link => (
            <li
              className="aaa-footer__link-item"
              key={'footer_' + link.text.replace(' ', '_')}
            >
              <MetricsElement>
                <RegularLink
                  className="aaa-footer__link"
                  to={link.url}
                  target={link.newTab && "_blank"}
                  data-metrics-event-name="footer_nav_click"
                  data-metrics-label={link.text}
                  onClick={() => {
                    if (location.pathname === link.url) {
                      event.preventDefault();
                      document.location.replace(link.url);
                    }
                  }}
                >
                  {link.text}
                </RegularLink>
              </MetricsElement>
            </li>
          ))}
        </ul>
      )}
    </Location>
  );
};

class FooterSource extends Component {
  constructor(props) {
    super(props);
    const { type } = props;
    this.footer = props.data.allFooterYaml.nodes.find(
      footer => footer.type === type
    );
  }

  render() {
    const { type } = this.props;
    const footer = this.footer;
    return (
      <footer
        id="footer"
        className={classnames(
          'aaa-footer',
          { 'aaa-footer-landing': type === 'landing' },
          { 'aaa-footer-instapage': type === 'instapage' }
        )}
      >
        <SeparatorWithGradient className="aaa-footer__separator" />
        {footer.linkGroups &&
        <ContentBox>
          <div className="aaa-footer__content">
            <div className="aaa-footer__columns">
              <div className="row justify-content-center">
                {footer.linkGroups.map(group => (
                  <div key={group.name} className="col-6 col-md-3">
                    <div className="aaa-footer__links-title">{group.name}</div>
                    <FooterLinksCol links={group.links} />
                  </div>
                ))}
              </div>
            </div>
            <FooterContacts contacts={footer.contacts} socials={footer.socials} />
          </div>
        </ContentBox>}
        {footer.disclaimer && (
          <div className="aaa-footer__disclaimer">
            <ContentBox>
              <div dangerouslySetInnerHTML={{__html: footer.disclaimer}} />
            </ContentBox>
          </div>
        )}
        <div className="aaa-footer__post-content">
          <ContentBox>
            <div
              className="aaa-footer__post-text"
              dangerouslySetInnerHTML={{
                __html: format(footer.legalText, {
                  instapageTerms: ReactDOMServer.renderToStaticMarkup(
                    <Link to={this.props.path}>{'Terms&Conditions'}</Link>
                  ),
                  year: moment().format('YYYY'),
                }),
              }}
            />
          </ContentBox>
        </div>
      </footer>
    );
  }
}

const Footer = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allFooterYaml {
          nodes {
            type
            linkGroups {
              name
              links {
                text
                url
                newTab
              }
            }
            contacts {
              title
              link {
                text
                url
              }
            }
            socials {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 50, maxHeight: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              url
            }
            disclaimer
            legalText
          }
        }
      }
    `}
    render={data => <FooterSource data={data} {...props} />}
  />
);

export default Footer;
