import React from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { MetricsElement } from 'react-metrics';
import Img from 'gatsby-image';
import { MainNavLink } from 'components/Shared/Link/Link';
import './PopUpMenu.scss';


export const FullWidthPopUp = ({ name, items, expanded }) => (
  <div className={classnames('menu-fullwidth', {'expanded': expanded})}>
    <AnimateHeight
      duration={300}
      height={expanded ? 'auto' : 0}
    >
      <ul className="menu-fullwidth__box">
        {items.map( (item, index) => (
          <li 
            className="menu-fullwidth__item"
            key={`menu_${name}_${index}`}
          >
            <MetricsElement key={`mainnav_${index}`}>
              <MainNavLink
                className="menu-fullwidth__link"
                to={item.url}
                data-metrics-event-name='header_main_nav_click'
                data-metrics-label={item.text}
              >
                <Img
                  className="menu-fullwidth__img"
                  alt={item.text}
                  fluid={item.image.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={false}
                />
                <span className="menu-fullwidth__label">{item.text}</span>
              </MainNavLink>
            </MetricsElement>
          </li>
        ))}
      </ul>
    </AnimateHeight>
  </div>
);

export const PopUp = ({ name, items, expanded }) => (
  <div className="menu-popup">
    <AnimateHeight
      duration={300}
      height={expanded ? 'auto' : 0}
    >
      <ul className="menu-popup__box">
        {items.map( (item, index) => (
          <li key={`menu_${name}_${index}`}>
            { 
              item.label && 
              (
                <span className="menu-popup__label">
                  {item.label}
                </span>
              )
            }
            <MetricsElement>
              <MainNavLink 
                className="menu-popup__link"
                to={item.url}
                target={item.newTab && '_blank'}
                data-metrics-event-name='header_main_nav_click'
                data-metrics-label={item.text}
              >
                {item.text}
              </MainNavLink>
            </MetricsElement>
          </li>
        ))}
      </ul>
    </AnimateHeight>
  </div>
);
