import React, { Component } from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { metrics } from 'react-metrics';
import Notice from 'components/Notice/Notice';
import Header from 'components/Header';
import Footer from 'components/Footer/Footer';
// import GatherEmailPopUp from 'components/GatherEmailPopUp';
import Auth from 'components/Shared/Auth';
import CTAPopUpContext from 'components/Shared/CTAPopUpContext';
import ZipContext from 'components/Shared/ZipContext';
import './Layout.scss';
import 'aos/dist/aos.css';
import MicrositeFooterFAQ from '../MicrositeFooterFaq/MicrositeFooterFaq';


const LayoutOptions = {
  standard: { headerFooterType: 'common', noswap: false },
  error: { headerFooterType: 'error', noswap: false },
  instapage: { headerFooterType: 'instapage', noswap: false },
  welcomekit: { headerFooterType: 'welcomekit', noswap: false },
  plain: { headerFooterType: null, noswap: false },
};

class Layout extends Component {
  constructor(props) {
    super(props);

    this.onSuccess = (name) => {
      this.setState(prevState => {
        return {
          headerPopUp: { 
            ...prevState.headerPopUp,
            submittedName: name
          },
        };
      });
    };

    this.togglePopUp = (showPopUp = null) => {
      this.setState(prevState => {
        const newShowPopUp =
          showPopUp === null ? !prevState.headerPopUp.showPopUp : showPopUp;
        return {
          headerPopUp: { ...prevState.headerPopUp, showPopUp: newShowPopUp },
        };
      });
    };

    this.togglePopUpButton = (showPopUpButton = false) => {
      this.setState(prevState => {
        return {
          headerPopUp: { 
            ...prevState.headerPopUp,
            showPopUpButton: showPopUpButton
          },
        };
      });
    }

    this.setZip = ({ zip, club, fullAccess }) => {
      this.setState(prevState => {
        return { zipInfo: { ...prevState.zipInfo, zip, club, fullAccess } };
      });
    };

    this.state = {
      showNotice: this.props.location.pathname === '/',
      mounted: false,
      headerPopUp: {
        showPopUp: false,
        showPopUpButton: true,
        submittedName: null,
        onSuccess: this.onSuccess,
        togglePopUp: this.togglePopUp,
        togglePopUpButton: this.togglePopUpButton,
      },
      zipInfo: {
        zip: null,
        club: null,
        fullAccess: null,
        setZip: this.setZip,
      },
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  // TODO: Create and reference header/footer version for after Auth flow here

  render() {
    const { children, location, type, noswap, logoLink } = this.props;
    const defaultOptions = {...LayoutOptions[type]};
    if (typeof(noswap) !== "undefined") {
      defaultOptions.noswap = noswap;
    }
    const { mounted, showNotice } = this.state;
    const HeaderComponent = mounted && Auth.isAuthenticated() ? Header : Header;
    const FooterComponent = mounted && Auth.isAuthenticated() ? Footer : Footer;
    const extraProps = defaultOptions.noswap ? { 'data-calltrk-noswap': true } : {};
    return (
      <div className={classnames({ 'show-notice': showNotice })}>
        <Notice onClose={() => this.setState({ showNotice: false })} />
        <div className="aaa-content">
          <CTAPopUpContext.Provider value={this.state.headerPopUp}>
            <ZipContext.Provider value={this.state.zipInfo}>
              {defaultOptions.headerFooterType &&
              <HeaderComponent 
                type={defaultOptions.headerFooterType}
                logoLink={logoLink}
              />}
              <div className="aaa-content-box" {...extraProps}>
                {children}
              </div>
              {/* <GatherEmailPopUp /> */}
            </ZipContext.Provider>
          </CTAPopUpContext.Provider>
          {defaultOptions.headerFooterType == 'welcomekit' ? (
            <MicrositeFooterFAQ></MicrositeFooterFAQ>
          ) : (
            defaultOptions.headerFooterType && (
              <FooterComponent
                type={defaultOptions.headerFooterType}
                path={location.pathname}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideNavigation: PropTypes.bool,
};

const config = {
  debug: false,
  vendors: [
    {
      name: 'Segment',
      api: {
        pageView: (eventName, args) => {
          window.analytics.page(args);
        },
        track: (eventName, args) => {
          window.analytics.track(eventName, args);
        },
        identify: (userId, args) => {
          if (userId === 'anonymous') {
            window.analytics.identify(args);
          } else {
            window.analytics.identify(userId, args);
          }
        },
      },
    },
  ],
};

const options = {
  autoTrackPageView: true,
  useTrackBinding: false,
  attributePrefix: 'data-metrics',
  suppressTrackBindingWarning: true,
};

export default metrics(config, options)(Layout);
